/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */
import './tailwind';
import './bootstrap';
import './gsap.min';
// any CSS you import will output into a single css file (app.scss in this case)
import './styles/app.scss';
// start the Stimulus application
const $ = require('jquery');
import './jquery-validation-1.19.3/dist/jquery.validate';
// this "modifies" the jquery module: adding behavior to it
// the bootstrap module doesn't export/return anything
require('bootstrap');


